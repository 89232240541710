import '../css/common.css'
import '../css/header.css';
import '../css/global.css'
import '../css/footer.css'
import '../css/layout.css'
import { WOW } from 'wow';
var scroH = $(document).scrollTop();
if (scroH > 100) {  //距离顶部大于100px时
  $('#scrollTop').show()
} else {
  $('#scrollTop').hide()
}

$(document).scroll(function () {
  var scroH = $(document).scrollTop();  //滚动高度
  var viewH = $(window).height();  //可见高度 
  var contentH = $(document).height();  //内容高度

  if (scroH > 100) {  //距离顶部大于100px时
    $('#scrollTop').fadeIn().show()
  } else {
    $('#scrollTop').fadeOut().hide()
  }
  if (contentH - (scroH + viewH) <= 100) {  //距离底部高度小于100px

  }
  if (contentH = (scroH + viewH)) {  //滚动条滑到底部啦
  }

});

//滚动到顶部
$('#scrollTop').click(function () {
  $('html,body').animate({ scrollTop: '0px' }, 800)
})


var lis = $('#nav-item li')
for (let i = 0; i < lis.length; i++) {
  if (lis[i].children[0].pathname == window.location.pathname) {
    lis[i].children[0].className = 'active'
  }
}

window.onload = function () {
  if (!(/msie [6|7|8|9]/i.test(navigator.userAgent))) {
    new WOW({live:false}).init();
  };
}
